








import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      totalTime: 0,
      endTimeNow: false,
    };
  },
  props: {
    startTime: Date,
    endTime: Date,
    enableCountdown: Boolean,
    showSeconds: {
      default: true,
      type: Boolean,
    },
    stringAfterHours: {
      default: ":",
      type: String,
    },
    stringAfterMinutes: {
      default: ":",
      type: String,
    },
    stringAfterSeconds: {
      default: "",
      type: String,
    },
  },
  computed: {
    hour() {
      let newEndTime =
        this.endTimeNow === false ? this.endTime : this.endTimeNow;
      let h = Math.trunc((newEndTime - this.startTime) / 1000 / 3600);
      return h > 9 ? h : "0" + h;
    },
    min() {
      let newEndTime =
        this.endTimeNow === false ? this.endTime : this.endTimeNow;
      let m = Math.trunc((newEndTime - this.startTime) / 1000 / 60) % 60;
      return m > 9 ? m : "0" + m;
    },
    sec() {
      let newEndTime =
        this.endTimeNow === false ? this.endTime : this.endTimeNow;
      let s = Math.trunc((newEndTime - this.startTime) / 1000) % 60;
      return s > 9 ? s : "0" + s;
    },
  },
  watch: {
    totalTime: {
      handler(value) {
        if (this.enableCountdown) {
          setTimeout(() => {
            this.endTimeNow = new Date().setMilliseconds(0);
            this.totalTime++;
          }, 1000);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
});
