






































import Vue, { PropType } from "vue";
import Countdown from "./Countdown.vue";
import { Session, Timing } from "./types";

export default Vue.extend({
  data() {
    return {};
  },
  components: { Countdown },
  props: {
    session: {
      type: Object as PropType<Session>,
      required: true,
    },
  },
  methods: {
    getLastTimingFromCurrentSession(): Timing {
      const timings = this.session.timings;
      return timings[Object.keys(timings).pop()];
    },
    getFirstTimingFromCurrentSession(): Timing | false {
      const timings = this.session.timings;
      return timings.length ? timings[0] : false;
    },
  },
});
