export type Stuff = {
  name: string;
  color: string;
  amounts: string[];
};

export type Timing = {
  time: Date;
  amount: string;
  stuff: string;
};

export type Session = {
  stuffs: Stuff[];
  timings: Timing[] | [];
};

export const defaultSession: Session = {
  stuffs: [
    {
      name: "G",
      color: "#5b8f94",
      amounts: [
        "0,1 ml",
        "0,2 ml",
        "0,3 ml",
        "0,4 ml",
        "0,5 ml",
        "0,6 ml",
        "0,7 ml",
        "0,8 ml",
        "0,9 ml",
        "1,0 ml",
        "1,1 ml",
        "1,2 ml",
        "1,3 ml",
        "1,4 ml",
        "1,5 ml",
        "1,6 ml",
        "1,7 ml",
        "1,8 ml",
        "1,9 ml",
        "2,0 ml",
        "2,1 ml",
        "2,2 ml",
        "2,3 ml",
        "2,4 ml",
        "2,5 ml",
      ],
    },
    {
      name: "S",
      color: "#b8bbb8",
      amounts: ["Small", "Normal", "Big"],
    },
    {
      name: "E",
      color: "#1d6745",
      amounts: [
        "20 mg",
        "40 mg",
        "60 mg",
        "80 mg",
        "100 mg",
        "120 mg",
        "140 mg",
        "160 mg",
        "180 mg",
        "200 mg",
      ],
    },
    {
      name: "K",
      color: "#4e152a",
      amounts: ["Small", "Normal", "Big"],
    },
    {
      name: "W",
      color: "#458945",
      amounts: ["Micro", "Small", "Normal", "Big"],
    },
    {
      name: "A",
      color: "#855e7e",
      amounts: [
        "1 cl",
        "2 cl",
        "3 cl",
        "4 cl",
        "0,3l Beer",
        "0,5l Beer",
        "Wine",
      ],
    },
    {
      name: "L",
      color: "#a71788",
      amounts: [
        "20 ng",
        "40 ng",
        "60 ng",
        "80 ng",
        "100 ng",
        "120 ng",
        "140 ng",
        "160 ng",
        "180 ng",
        "200 ng",
        "220 ng",
        "240 ng",
        "260 ng",
        "280 ng",
      ],
    },
    {
      name: "M",
      color: "#16a9a2",
      amounts: ["Micro", "Small", "Normal", "Big"],
    },
  ],
  timings: [],
};
