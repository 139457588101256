







































import Vue, { PropType } from "vue";
import { Stuff } from "./types";

export default Vue.extend({
  props: {
    stuff: {
      type: Object as PropType<Stuff | {}>,
      required: true,
    },
  },
  data() {
    return {
      time: "",
      timeContext: null,
      selectedAmount: "-",
    };
  },
  methods: {
    onTimeContext(ctx) {
      this.timeContext = ctx;
    },
    setTimeToNow() {
      this.time = new Date().toTimeString().slice(0, 8);
    },
    onModalShow() {
      this.setTimeToNow();
      this.selectedAmount = "-";
    },
  },
});
