




















import Vue, { PropType } from "vue";
import { Session } from "./types";
import images from "assets/*.jpg";

export default Vue.extend({
  data() {
    return {
      images,
    };
  },
  props: {
    session: {
      type: Object as PropType<Session>,
      required: true,
    },
  },
});
