
























































































import Vue, { PropType } from "vue";
import Countdown from "./Countdown.vue";
import { Session, Stuff } from "./types";

export default Vue.extend({
  components: { Countdown },
  data() {
    return {};
  },
  props: {
    sessions: {
      type: Array as PropType<Session[]>,
      required: true,
    },
  },
  methods: {
    getStuffColor(session: Session, stuffName: string): string {
      const stuff = session.stuffs.find(
        (stuff: Stuff) => stuff.name === stuffName
      );

      return stuff.color;
    },
  },
});
