








































import Vue from "vue";
import AmountModal from "./AmountModal.vue";
import AppFooter from "./AppFooter.vue";
import AppHeader from "./AppHeader.vue";
import Sessions from "./Sessions.vue";
import StuffButtons from "./StuffButtons.vue";
import Timer from "./Timer.vue";
import { defaultSession, Session, Stuff, Timing } from "./types";
import jsonStringifyDate from "json-stringify-date";

export default Vue.extend({
  data() {
    const sessions: [Session] = [JSON.parse(JSON.stringify(defaultSession))];

    return {
      defaultSession,
      sessions,
      currentStuff: {},
    };
  },
  components: {
    AppHeader,
    AppFooter,
    Sessions,
    Timer,
    StuffButtons,
    AmountModal,
  },
  mounted() {
    this.loadSessionsFromLocalStorage();
  },
  methods: {
    loadSessionsFromLocalStorage() {
      if (localStorage.getItem("sessions")) {
        try {
          this.sessions = jsonStringifyDate.parse(
            localStorage.getItem("sessions")
          );
        } catch (e) {
          localStorage.removeItem("sessions");
        }
      }
    },
    saveSessions() {
      const parsed = jsonStringifyDate.stringify(this.sessions);
      localStorage.setItem("sessions", parsed);
    },
    getCurrentSession(): Session {
      return this.sessions[Object.keys(this.sessions).pop()];
    },
    openAmountModal(stuff: Stuff): void {
      this.currentStuff = stuff;
      this.$root.$emit("bv::show::modal", "amount-modal", "#amount-modal");
    },
    addTiming(
      stuff: Stuff,
      amount: string,
      hours: number,
      minutes: number
    ): void {
      const currentSession = this.getCurrentSession();
      const date = new Date();
      date.setHours(hours, minutes);

      currentSession.timings.push({
        time: date,
        amount: amount,
        stuff: stuff.name,
      });

      // sort timings, because maybe the user added on in the past
      currentSession.timings.sort(
        (a: Timing, b: Timing) => a.time.getTime() - b.time.getTime()
      );

      this.$root.$emit("bv::hide::modal", "amount-modal", "#amount-modal");
      this.saveSessions();
      this.showSuccessMessage("Timing added!");
    },
    removeTiming(sessionIndex: number, timingIndex: number): void {
      this.$bvModal
        .msgBoxConfirm("Are you sure?")
        .then((value) => {
          if (value) {
            const session = this.sessions[sessionIndex];
            session.timings.splice(timingIndex, 1);
            this.saveSessions();
            this.showSuccessMessage("Timing removed!");
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    removeSession(sessionIndex: number): void {
      this.$bvModal
        .msgBoxConfirm("Are you sure?")
        .then((value) => {
          if (value) {
            this.sessions.splice(sessionIndex, 1);
            this.saveSessions();
            this.showSuccessMessage("Session deleted!");
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    askToStartNewSession(): void {
      this.$bvModal
        .msgBoxConfirm("Are you sure?")
        .then((value) => {
          if (value) {
            this.sessions.push(JSON.parse(JSON.stringify(this.defaultSession)));
            this.saveSessions();
            this.showSuccessMessage("New Session!");
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    showSuccessMessage(message: string) {
      this.$bvToast.toast(message, {
        title: `Success!`,
        toaster: "b-toaster-bottom-center",
        solid: true,
        appendToast: false,
        variant: "success",
        autoHideDelay: 2000,
      });
    },
  },
});
